import { useState, useEffect } from "react";
import "./common.scss";

const UploadFile = ({ data, setAnswer, formValidation = true, formClass }) => {
  const [selectedOption, setSelectedOption] = useState(
    data.answer?.value || ""
  );
  const [validForm, setFormValidation] = useState(formValidation);

  useEffect(() => {
    setFormValidation(formValidation);
    if (data?.answer) {
      setSelectedOption(data.answer?.value);
    } else setSelectedOption("");
  }, [data, formValidation]);

  return (
    <div className={`w-full ${formClass}`}>
      <div className="form-title pt-3">
        {data?.label}
        {data?.validate?.required ? (
          <span className={!formValidation ? "text-red-700" : "text-gray-600"}>
            {" "}
            *
          </span>
        ) : null}
      </div>
      <form className="flex-grow" onSubmit={(e) => e.preventDefault()}> {/* Prevent form submission */}
        <div className="radio-common">
          <label className="relative">
            <input
              type="file"
              id={data?.key}
              accept="*"
              disabled={data?.disabled || false}
              style={{ display: "none" }} // Hide the default file input
              onChange={(e) => {
                setSelectedOption(e.target.files[0]);
                setAnswer(data, { value: e.target.files[0] });
                setFormValidation(true);
              }}
              className="text-field py-3 px-2 "
            />
            <button
            className="rounded px-2 py-1 my-3 mx-2 cursor-pointer primary_background"
              type="button" // Prevent the button from submitting the form
              onClick={() => document.getElementById(`${data?.key}`).click()}
            >
              Choose File
            </button>
            {selectedOption?.name ? (
              <span className="px-3"><b>Selected file: </b>{selectedOption?.name}</span>
            ) : null}
            {data?.description && (
              <div className="px-2 text-gray-500 text-sm">
                {data?.description}
              </div>
            )}
          </label>
        </div>
      </form>
      {data?.validate?.required && !formValidation ? (
        <span className="text-red-700 font-light text-xs">
          {data?.invalidMessage || "Required Field"}
        </span>
      ) : null}
    </div>
  );
};
export default UploadFile;
