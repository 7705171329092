import React, { useEffect, useState, useContext } from "react";
import "./scheduler.scss";
import axios from "axios";
import FormRendererContext from "../../services/context/FormRendererContext";
import moment from "moment";
import Loader from "../../components/common/loader";
import ScheduleAppointment from "../scheduleAppointment";
import TextField from "../../components/common/TextField";
import DateInput from "../../components/common/DateInput";
import Email from "../../components/common/Email";
import ModalBox from "../../components/common/modal";

const NewSchedulerWidget = ({
  widget,
  handleNextScreenPage = () => {},
  handleBackScreenPage = () => {},
  setAnswerForWidget = () => {},
}) => {
  const [schedulerId, setSchedulerId] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [reasons, setReasons] = useState([]);
  const [selectedReason, setSelectedReason] = useState(null);
  const [appointmentSlot, setAppointmentSlot] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [providerDetails, setProviderDetails] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const [firstName, setFirst] = useState("");
  const [lastName, setLast] = useState("");
  const [dob, setDateOfBirth] = useState(null);
  const [email, setEmail] = useState("");

  const {
    scheduler_id,
    setScheduler_id,
    provider_id,
    setProvider_id,
    reason_id,
    setReason_id,
    appointment_id,
    setAppointment_id,
    patient_id,
    setPatient_id,
    department_id,
    setDepartment_id,
    HBxFirstName,
    HBxLastName,
    HBxEmail,
    HBxDob,
    HBxReasonForVisit,
    appointmentData,
    setAppointmentData,
  } = useContext(FormRendererContext);

  // const fetchDepartments = async (schedulerId) => {
  //   setIsLoading(true);
  //   let API_URL = `${
  //     process.env?.REACT_APP_SCHEDULER_URL ||
  //     "https://apps-sandbox.healthblox.care/scheduler"
  //   }/api/v1/providers/${schedulerId}/departments?providerlist=true`;
  //   try {
  //     const response = await axios.get(API_URL);
  //     const { HBx } = response?.data;
  //     const { departments } = HBx;
  //     for (let i = 0; i < departments.length; i++) {
  //       departments[i].value = departments[i].departmentid;
  //     }
  //     setDepartments(departments);
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     setIsLoading(false);
  //   }
  // };

  // const fetchReasons = async () => {
  //   setIsLoading(true);
  //   let API_URL = `${
  //     process.env?.REACT_APP_SCHEDULER_URL ||
  //     "https://apps-sandbox.healthblox.care/scheduler"
  //   }/api/v1/appointments/${schedulerId}/reasons?departmentId=150&providerId=27`;
  //   try {
  //     const response = await axios.get(API_URL);
  //     const { HBx } = response?.data;
  //     const { patientappointmentreasons } = HBx;
  //     for (let i = 0; i < patientappointmentreasons.length; i++) {
  //       patientappointmentreasons[i].id = patientappointmentreasons[i].reasonid;
  //       patientappointmentreasons[i].label =
  //         patientappointmentreasons[i].reason;
  //     }
  //     setReasons(patientappointmentreasons);
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     setIsLoading(false);
  //   }
  // };

  const fetchProviderDetails = async (schedulerId, providerId) => {
    setIsLoading(true);
    let API_URL = `${
      process.env?.REACT_APP_SCHEDULER_URL ||
      "https://apps-sandbox.healthblox.care/scheduler"
    }/api/v1/providers/${schedulerId}/id/${providerId}`;
    try {
      const response = await axios.get(API_URL);
      const { HBx } = response?.data;
      setIsLoading(false);
      return HBx;
    } catch (error) {
      // console.log(error);
      setIsLoading(false);
      return null;
    }
  };
  const handleOptionChange = (val) => {
    setSelectedDepartment(val);
    setDepartment_id(val);
  };

  const handleReasonChange = async (schedulerId) => {
    setIsLoading(true);
    let providersList = [];

    let providerlist = ["111", "27", "108", "109", "72", "87", "88", "67"];
    for (let i = 0; i < providerlist.length; i++) {
      let providerDetail = await fetchProviderDetails(
        schedulerId,
        providerlist[i]
      );
      providersList.push({ providerId: providerlist[i], providerDetail });
    }
    setProviderDetails(providersList);
    // setCurrentStep((prev) => prev + 1);
    setIsLoading(false);
  };

  useEffect(() => {
    if (widget?.id) {
      setSchedulerId(widget.id);
      setScheduler_id(widget.id);
      // fetchDepartments(widget.id);
      handleReasonChange(widget.id);
    }
    if (HBxDob) {
      setDateOfBirth(HBxDob);
    }
    if (HBxEmail) {
      setEmail(HBxEmail);
    }
    if (HBxFirstName) {
      setFirst(HBxFirstName);
    }
    if (HBxLastName) {
      setLast(HBxLastName);
    }
  }, []);

  const handleBackClick = () => {
    if (currentStep === 0) {
      handleBackScreenPage();
    }
    if (currentStep === 1) {
      setCurrentStep((prev) => prev - 1);
    }
    if (currentStep === 2) {
      setCurrentStep((prev) => prev - 1);
    }
  };
  async function checkForUsers() {
    let API_URL = `${
      process.env?.REACT_APP_SCHEDULER_URL ||
      "https://apps-sandbox.healthblox.care/scheduler"
    }/api/v1/patients/${schedulerId}?firstName=${firstName}&lastName=${lastName}&DOB=${encodeURIComponent(
      dateFormatter(dob)
    )}&email=${encodeURIComponent(email)}`;
    try {
      const response = await axios.get(API_URL);
      const { HBx } = response.data;
      if (HBx && HBx.patientId) return HBx.patientId;
      return null;
    } catch (error) {
      // console.log(error);
      return null;
    }
  }
  async function createAppointment(patientID) {
    setIsLoading(true);
    let API_URL = `${
      process.env?.REACT_APP_SCHEDULER_URL ||
      "https://apps-sandbox.healthblox.care/scheduler"
    }/api/v1/appointments/${schedulerId}`;
    try {
      const response = await axios.put(API_URL, {
        appointmentId: appointment_id,
        patientId: patientID,
        bookingNote: HBxReasonForVisit || "Health Check-up",
        departmentId: department_id || "150",
        reasonId: reason_id || "1301",
      });
      const { HBx } = response.data;
      setAnswerForWidget(HBx);
      setIsLoading(false);
    } catch (error) {
      const { response } = error;
      const { HBX } = response.data;
      // console.log(error);
      HBX?.message && alert(HBX?.message);
      setIsLoading(false);
    }
  }
  const handleNextClick = async () => {
    if (currentStep === 2) {
      if (firstName && lastName && dob && email) {
        setIsLoading(true);
        if (patient_id) {
          await createAppointment(patient_id);
          setCurrentStep((prev) => prev + 1);
          setOpenModal(true);
          setIsLoading(false);
        } else {
          let patientID = await checkForUsers();
          if (patientID) {
            await createAppointment(patientID);
            setCurrentStep((prev) => prev + 1);
            setOpenModal(true);
            setPatient_id(patientID);
            setIsLoading(false);
          } else {
            let API_URL = `${
              process.env?.REACT_APP_SCHEDULER_URL ||
              "https://apps-sandbox.healthblox.care/scheduler"
            }/api/v1/patients/${schedulerId}`;
            try {
              const response = await axios.post(API_URL, {
                firstName: firstName,
                lastName: lastName,
                DOB: dateFormatter(dob),
                departmentId: department_id || "150",
                email: [email],
              });
              const { HBx } = response?.data;
              const { patientId } = HBx;
              await createAppointment(patientId);
              setCurrentStep((prev) => prev + 1);
              setOpenModal(true);
              setPatient_id(patientId);
              setIsLoading(false);
            } catch (error) {
              // console.log(error);
              setIsLoading(false);
            }
          }
        }
      }
    }
    if (currentStep === 1) {
      setCurrentStep((prev) => prev + 1);
    }
    if (currentStep === 0) {
      setCurrentStep((prev) => prev + 1);
    }
  };
  function setFirstName(name, data) {
    setFirst(data.value);
  }
  function setLastName(name, data) {
    setLast(data.value);
  }
  function setDOB(name, data) {
    setDateOfBirth(data.value);
  }
  function dateFormatter(date) {
    const formattedDate = moment(date?.$d).format("MM/DD/YYYY");
    return formattedDate;
  }
  function setEmailId(name, data) {
    setEmail(data.value);
  }
  return (
    <div className="">
      {currentStep === 0 ? (
        <div className="h-dvh w-screen bg-purple-900 text-center mx-auto content-center text-white text-2xl font-semi-bold">
          CV Screen
        </div>
      ) : (
        <div className="pb-8">
          {!isLoading ? (
            <>
              {currentStep === 1 && (
                <div>
                  <ScheduleAppointment
                    data={providerDetails}
                    activateNextClick={handleNextClick}
                  />
                </div>
              )}

              {currentStep === 2 && (
                <div>
                  <div className="content-width pt-10 pb-5 px-5">
                    <div className="font-semi-bold text-center text-3xl pb-5 font_georgia">
                      Please verify your credentials:
                    </div>
                    <div className="form-width mx-auto">
                      <TextField
                        data={{
                          label: "First Name",
                          answer: { value: firstName },
                        }}
                        setAnswer={setFirstName}
                      />
                      <TextField
                        data={{
                          label: "Last Name",
                          answer: { value: lastName },
                        }}
                        setAnswer={setLastName}
                      />
                      <DateInput
                        data={{ label: "DOB", answer: { value: dob } }}
                        setAnswer={setDOB}
                      />
                      <Email
                        data={{ label: "Email", answer: { value: email } }}
                        setAnswer={setEmailId}
                      />
                    </div>
                  </div>
                </div>
              )}
              {currentStep === 3 && openModal && (
                <>
                  <ModalBox
                    openModal={true}
                    leftButtonText={"Close"}
                    rightButtonText={"Continue"}
                    rightButtonCallback={() => {
                      setOpenModal(false);
                      handleNextScreenPage();
                    }}
                    title={"Thank You !"}
                    SubTitle={
                      "Your Appointment has been parked successfully, Please confirm your payment to schedule your visit"
                    }
                    hideClose={true}
                  />
                </>
              )}
            </>
          ) : (
            <div className="mt-20">
              <Loader />
            </div>
          )}
        </div>
      )}
      <div className="bottom-bar-shadow bottom-bar-scheduler-shadow">
        <div className="flex justify-between pt-3 m-auto bottom-bar-scheduler w-full">
          <button onClick={handleBackClick} className="text_button">
            Back
          </button>
          <div className={currentStep === 1 ? "hidden" : ""}>
            <div onClick={handleNextClick} className="primary_button">
              {currentStep === 2 ? "Schedule Appointment" : "Continue"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewSchedulerWidget;
