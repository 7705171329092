import React, { useEffect, useState, useContext } from "react";
import "./scheduler.scss";
import axios from "axios";
import FormRendererContext from "../../services/context/FormRendererContext";
import Loader from "../../components/common/loader";
import ScheduleAppointment from "../scheduleAppointment";
import ModalBox from "../../components/common/modal";

const SchedulerWidgetForReschedule = ({
  widget,
  handleNextScreenPage = () => {},
  handleBackScreenPage = () => {},
  setAnswerForWidget = () => {},
}) => {
  const { answerData } = useContext(FormRendererContext);

  const [schedulerId, setSchedulerId] = useState(widget.id);
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [providerDetails, setProviderDetails] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const {
    setScheduler_id,
    provider_id,
    appointment_id,
    hashIdFromQP,
    journeyId,
    patientTZ,
  } = useContext(FormRendererContext);

  const fetchProviderDetails = async (schedulerId, providerId) => {
    // setIsLoading(true);
    let API_URL = `${
      process.env?.REACT_APP_SCHEDULER_URL ||
      "https://apps-sandbox.healthblox.care/scheduler"
    }/api/v1/providers/${schedulerId}/id/${providerId}`;
    try {
      const response = await axios.get(API_URL);
      const { HBx } = response?.data;
      // setIsLoading(false);
      return HBx;
    } catch (error) {
      console.log(error);
      // setIsLoading(false);
      return null;
    }
  };

  const fetchSlots = async () => {
    let providersList = [];
    let providerlist = [provider_id];
    for (let i = 0; i < providerlist.length; i++) {
      let providerDetail = await fetchProviderDetails(
        schedulerId,
        providerlist[i]
      );
      providersList.push({ providerId: providerlist[i], providerDetail });
    }
    setProviderDetails(providersList);
    setIsLoading(false);
  };

  useEffect(() => {
    if (widget?.id) {
      setSchedulerId(widget.id);
      setScheduler_id(widget.id);
    }
    if (provider_id) {
      fetchSlots();
    }
  }, []);

  const handleBackClick = () => {
    if (currentStep === 0) {
      handleBackScreenPage();
    }
    if (currentStep === 1) {
      setCurrentStep((prev) => prev - 1);
    }
    if (currentStep === 2) {
      setCurrentStep((prev) => prev - 1);
    }
    if (currentStep === 3) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  async function createAppointment(appointmentId, appointmentSlotData) {
    setIsLoading(true);
    const referralSource =
      journeyId === "672266365b3a1419fb71ede0" ||
      journeyId === "673ae4d8413351c0414fd152"
        ? "amerigas"
        : journeyId === "6724e68514a3e05197fd8aff" ||
          journeyId === "673ae4e7413351c0414fd153"
        ? "cpapcom"
        : journeyId === "6724e69114a3e05197fd8b00" ||
          journeyId === "673ae4f3413351c0414fd154"
        ? "default"
        : "";
    const sanitisedSlot = structuredClone(appointmentSlotData?.slot);

    let API_URL = `${
      process.env?.REACT_APP_SCHEDULER_URL ||
      "https://apps-sandbox.healthblox.care/scheduler"
    }/api/v1/appointments/reschedule/${hashIdFromQP}`;
    try {
      const response = await axios.put(API_URL, {
        reasonId: 41,
        reason: answerData?.reasonForReschedulingAppointment?.value,
        hash: hashIdFromQP,
        newAppointmentId: appointmentId, //from slots api,
        meta: {
          referralSource,
          providerTZ: appointmentSlotData?.providerTimeZoneOffset || null,
          slotData: sanitisedSlot,
          patientTZ: patientTZ,
        },
      });
      const { HBx } = response.data;
      setAnswerForWidget(HBx);
      setCurrentStep((prev) => prev + 1);
      setOpenModal(true);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }
  const handleNextClick = async (appointmentId, appointmentSlotData) => {
    if (currentStep === 0) {
      setIsLoading(true);
      await createAppointment(appointmentId, appointmentSlotData);
    }
  };
  return (
    <div className="">
      {!isLoading ? (
        <>
          {currentStep === 0 && (
            <>
              {providerDetails && providerDetails.length ? (
                <div>
                  <ScheduleAppointment
                    data={providerDetails}
                    activateNextClick={(appointmentId, appointmentSlotData) =>
                      handleNextClick(appointmentId, appointmentSlotData)
                    }
                  />
                </div>
              ) : (
                <div className="text-xl text-center">
                  <div className="px-10 inline-block py-3 rounded bg-gray-200 my-5">
                    No providers found for this state
                  </div>
                </div>
              )}
            </>
          )}
          {currentStep === 1 && openModal && (
            <>
              <ModalBox
                openModal={true}
                leftButtonText={"Close"}
                rightButtonText={"Continue"}
                rightButtonCallback={() => {
                  setOpenModal(false);
                  handleNextScreenPage();
                }}
                title={"Thank You !"}
                SubTitle={"Your Appointment has been Scheduled successfully"}
                hideClose={true}
              />
            </>
          )}
        </>
      ) : (
        <div className="mt-20">
          <Loader />
        </div>
      )}
      <div className="bottom-bar-shadow bottom-bar-scheduler-shadow">
        <div className="flex justify-between pt-3 m-auto bottom-bar-scheduler w-full">
          <button onClick={handleBackClick} className="text_button">
            Back
          </button>
          {/* <div className={currentStep === 0 ? "hidden" : ""}>
            <div onClick={handleNextClick} className="primary_button">
              {currentStep === 1 ? "Schedule Appointment" : "Continue"}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default SchedulerWidgetForReschedule;
